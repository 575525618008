import React from 'react';
import { Button, Table, Modal, Row, Col, Figure } from 'react-bootstrap';
import portraitImage from  '../fake-portrait.png';

interface AppointmentProps{
    size: "sm" | "normal"
    activeSessions: boolean
}

interface AppointmentState{
    workerShown: boolean;
}

class AppointmentList extends React.Component<AppointmentProps, AppointmentState> {

    constructor(props: AppointmentProps){
        super(props);
        this.showInfo = this.showInfo.bind(this);
        this.closeInfo = this.closeInfo.bind(this);

        this.state = {
            workerShown: false
        };
    }

    showInfo(){
        this.setState({
            workerShown: true
        });
    }

    closeInfo(){
        this.setState({
            workerShown:false
        })
    }


    renderLocation(header: boolean){
        if(this.props.size == 'normal' && header){
            return (<th>Where</th>);
        }else if(this.props.size == 'normal' && !header){
            return(<td>1549 El Prado, San Diego, California</td>);
        }else{
            return null;
        }
    
    }

    renderActiveLocations(){
        if(this.props.activeSessions){
            return null;
        }else{
            return (<tr>
                <td>3/1/2019 1:14PM</td>
                {this.renderLocation(false)}
                <td>
                    <a href='#link'>
                        <span onClick={this.showInfo}>Anthony Logison</span>
                    </a>
                    <Modal size='lg' show={this.state.workerShown} onHide={this.closeInfo}>
                        <Modal.Header>Registered Worker</Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg='6' md='6' sm='12' xs='12'>
                                    <Figure>
                                        <Figure.Image
                                            width="200px"
                                            height="200px"
                                            alt="Anthony Logison"
                                            src={portraitImage}
                                        />
                                    </Figure>
                                </Col>
                                <Col lg='6' md='6' sm='12' xs='12'>
                                    <br />
                                    <h4>Name:</h4>
                                    <div>Anthony Logison</div>
                                    <h4>Credentials:</h4>
                                    <div>RN, CPR, etc.</div>
                                    <h4>Year in practice:</h4>
                                    <div>5 years</div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button  variant='secondary' onClick={this.closeInfo}>Close</Button>
                        </Modal.Footer>
                    </Modal> 
                </td>
                <td>Rejected<span className="dot dot-danger"></span></td>
            </tr>);
        }
    }

    render(){
        return (
        <Table bordered={true} responsive size="sm" style={{marginTop: "10px"}}>
            <thead className="thin-header">
                <tr>
                    <th>When</th>                    
                    {this.renderLocation(true)}
                    <th>Registered Worker</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {this.renderActiveLocations()}
                <tr>
                    <td>3/3/2019 1:14PM</td>
                    {this.renderLocation(false)}
                    <td>
                        <a href='#link'>
                            <span onClick={this.showInfo}>Anthony Logison</span>
                        </a>
                        <Modal size='lg' show={this.state.workerShown} onHide={this.closeInfo}>
                            <Modal.Header>Registered Worker</Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg='6' md='6' sm='12' xs='12'>
                                        <Figure>
                                            <Figure.Image
                                                width="200px"
                                                height="200px"
                                                alt="Anthony Logison"
                                                src={portraitImage}
                                            />
                                        </Figure>
                                    </Col>
                                    <Col lg='6' md='6' sm='12' xs='12'>
                                        <br />
                                        <h4>Name:</h4>
                                        <div>Anthony Logison</div>
                                        <h4>Credentials:</h4>
                                        <div>RN, CPR, etc.</div>
                                        <h4>Year in practice:</h4>
                                        <div>5 years</div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button  variant='secondary' onClick={this.closeInfo}>Close</Button>
                            </Modal.Footer>
                        </Modal> 
                    </td>
                    <td>Approved<span className="dot dot-success"></span></td>
                </tr>
                <tr>
                    <td>2/14/2019 8:30AM</td>
                    {this.renderLocation(false)}
                    <td><a href="#">Lynn Lammer</a></td>
                    <td>Completed<span className="dot dot-primary"></span></td>
                </tr>
            </tbody>
        </Table>
    )};
}

export default AppointmentList;