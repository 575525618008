import React, {Component, ComponentProps, SyntheticEvent, FormEvent} from 'react';
import { Form, FormLabel, FormText, FormControl, FormControlProps, Table, Row, Col } from "react-bootstrap";
import { string } from 'prop-types';
import './home.css';
import AppointmentList from './appointment-list';
import AppointmentModal from './Modal';

interface HomeState{
    collectionType?: string;
}

interface HomeProps{
    collectionType:  "WaterQuality" | "WaterSurvey" ;
}

const getCollection = (props:HomeProps) => props.collectionType; 

class Home extends Component<HomeProps, HomeState>{
    constructor(props: HomeProps){
        super(props);
        this.state = {
            collectionType: this.props.collectionType
        };

        this.collectionSelect = this.collectionSelect.bind(this);
    }

    collectionSelect(i: FormEvent<FormControlProps>){
        this.setState({
            collectionType: i.currentTarget.value
        })
    }

    render(){
        return (
            <div>
                <h3>Welcome to the A.R.C.C Portal</h3>
                <Row>
                    <Col lg="5">
                        <div>
                            <h4>Scheduled Appointments</h4>
                            <AppointmentModal></AppointmentModal>
                            <AppointmentList activeSessions={false} size='normal'></AppointmentList>
                        </div>
                    </Col>
                </Row>
            </div>
            
        );
    }
}
export default Home;