import React, { Component } from 'react';
import Header from "./components/header";
import Navigation from "./components/navbar";
import Footer from "./components/footer";
import { Container } from "react-bootstrap";
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './components/home';

class App extends Component {
  render() {
    return (
      <div className="App">       
        <BrowserRouter>
          <div>
            <Navigation></Navigation>
            <Container fluid={true}>
              <div className="mainView">
                <Route exact path="/" component={Home} />                
              </div>
            </Container>
          </div>
        </BrowserRouter>
        <Footer></Footer>
      </div>
    );
  }
}

export default App;
