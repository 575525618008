import React, { ReactPropTypes } from 'react';
import { Button, Modal, FormControl, ListGroup, Badge, Figure, Row, Col  } from 'react-bootstrap';
import AppointmentList from './appointment-list';
import '../Modal.css';
import portraitImage from  '../fake-portrait.png';

interface AppointmentModalState{
    shown: boolean;
    workerShown:boolean;
}

class AppointmentModal extends React.Component<any, AppointmentModalState> {

    constructor(props: any){
        super(props);
        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.closeInfo = this.closeInfo.bind(this);
        this.state = {
            shown:false,
            workerShown: false
        };
    }

    showModal(){
        this.setState({shown: true});
    }

    closeModal(){
        this.setState({shown: false});
    }

    showInfo(){
        this.setState({workerShown: true});
    }

    closeInfo(){
        this.setState({workerShown: false});
    }
    
    render(){
        return (
            <div>
                <Button onClick={this.showModal}>Click here to schedule another appointment</Button>
                <br/>
                <Modal size='lg' show={this.state.shown} onHide={this.closeModal} >
                    <Modal.Header>Create a new Appointment</Modal.Header>
                    <Modal.Body>
                        <AppointmentList activeSessions={true} size='sm'></AppointmentList>
                        <label>When</label>
                        <FormControl as="input">                        
                        </FormControl>
                        <div id="suggested-list">
                            <label>Suggested RWs</label>
                            <ListGroup >
                                <ListGroup.Item as='div'>
                                    <a href='#link'>
                                        <span onClick={this.showInfo}>Anthony Logison</span>
                                    </a>
                                    {/* <span onClick={this.showInfo}>
                                        <Badge pill variant='info'>
                                            Info
                                        </Badge>
                                    </span> */}
                                    <Modal size='lg' show={this.state.workerShown} onHide={this.closeInfo}>
                                        <Modal.Header>Registered Worker</Modal.Header>
                                        <Modal.Body>
                                            <Row>
                                                <Col lg='6' md='6' sm='12' xs='12'>
                                                    <Figure>
                                                        <Figure.Image
                                                            width="200px"
                                                            height="200px"
                                                            alt="Anthony Logison"
                                                            src={portraitImage}
                                                        />
                                                    </Figure>
                                                </Col>
                                                <Col lg='6' md='6' sm='12' xs='12'>
                                                    <br />
                                                    <h4>Name:</h4>
                                                    <div>Anthony Logison</div>
                                                    <h4>Credentials:</h4>
                                                    <div>RN, CPR, etc.</div>
                                                    <h4>Year in practice:</h4>
                                                    <div>5 years</div>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant='secondary' onClick={this.closeInfo}>Close</Button>
                                        </Modal.Footer>
                                    </Modal> 
                                </ListGroup.Item>
                                <ListGroup.Item as='div'>Lynn Lammer</ListGroup.Item>
                                <ListGroup.Item as='div'>Rory Robberts</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeModal}>Close</Button>
                        <Button variant="primary" onClick={this.closeModal}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default AppointmentModal;