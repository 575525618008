import React, {Component} from 'react';
import { Container } from 'react-bootstrap';

class Footer extends Component{
    render(){
        return (
            <Container>
                <footer>
                    <p>© {(new Date()).getFullYear()} - A.R.C.C Center </p>
                </footer>
            </Container>
        );
    }
}

export default Footer;