import React, { Component } from 'react';
import {Nav, Navbar, Badge, Container} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from '../ARCCLogo.png';
import '../navbar.css';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from 'react-router';


class Navigation extends Component{
    render(){
        return (            
            <Navbar className="mainNavBar" expand="lg">
                <Navbar.Brand href="#" style={{ backgroundColor: 'white'}}>
                    <img src={logo}  alt="ARCC Center"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse ref="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer exact to="/">
                            <Nav.Link className="custom-link-color">Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/Appointments">
                            <Nav.Link className="custom-link-color">Appointments</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/WaterSurvey">
                            <Nav.Link className="custom-link-color">Documents</Nav.Link>
                        </LinkContainer>                            
                    </Nav>
                    <Nav >
                        
                        <LinkContainer to="/Notification">
                            <Nav.Link>
                                <label className="messageStyler">Messages</label>                                
                                <Badge variant="danger">100</Badge>
                            </Nav.Link>
                        </LinkContainer>
                        <Nav.Link disabled as="a" className="custom-link-color">Frank Daniels</Nav.Link>
                        <Nav.Link className="custom-link-color">Logout</Nav.Link>                            
                    
                    </Nav>
                </Navbar.Collapse>                
            </Navbar>
        );
    }
}

export default Navigation;